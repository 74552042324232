<template>
  <div class="m-2">
    <table class="table table-sm" v-if="list && list.length">
      <tr v-for="(item, idx) in list" :key="idx">
        <td>{{ idx + 1 }}</td>
        <td>{{ item.name }}</td>
        <td>
          <a :href="item.url" target="blank">{{ item.url }}</a>
        </td>
      </tr>
    </table>

    <div v-else>всё ок</div>
  </div>
</template>
<script>
import { HTTPGET } from "@/api";
export default {
  name: "NotCodInSheme",
  mounted() {
    this.get_data();
  },
  data: () => ({
    list: null
  }),
  methods: {
    get_data() {
      HTTPGET({
        url: "template/ok-zip/not_cod_in_sheme.php"
      }).then(response => (this.list = response.data.scheme));
    }
  }
};
</script>
<style scoped>
td {
  font-size: 0.7em;
}
</style>
